@import "ui/styles/colors.scss";
                  @import "ui/styles/base.scss";
@import './reset.scss';

* {
  box-sizing: border-box;
  font-family: inherit;
}

html,
body {
  margin: 0;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, 'Apple SD Gothic Neo', 'Pretendard Variable', Roboto,
    'Noto Sans KR', 'Segoe UI', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
}
