// 공통 컴포넌트로 대체 전까지 임시로 사용하는 mixin입니다.
@mixin icon-outlined-button {
  cursor: pointer;
  border: 1px solid $color-gray-400;

  > svg {
    fill: $color-gray-800;
  }

  &:hover {
    background-color: $color-gray-100;

    > svg {
      fill: $color-gray-800;
    }
  }

  &.darkMode {
    border: 1px solid $color-gray-700;

    > svg {
      fill: $color-gray-600;
    }

    &:hover {
      background-color: $color-gray-700;
      border: 1px solid $color-gray-600;

      > svg {
        fill: $color-gray-400;
      }
    }
  }
}

@mixin modal-scroll {
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border: 3px solid $color-white;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-white;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    width: 10px;
    background: rgba($color-gray-500, 0.8);
    opacity: 0.8;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 4px transparent;
    background-clip: padding-box;
    border: 5px solid transparent;
  }
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-button:start:decrement,
  &::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 0;
    width: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    width: 10px;
    background: rgba($color-gray-500, 0.8);
    opacity: 0.8;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 4px transparent;
    background-clip: padding-box;
    border: 5px solid transparent;
  }
}

@mixin disabled-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

@mixin hidden-scroll {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin slider {
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    cursor: pointer;
  }

  &::-webkit-slider-thumb {
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: 50%;
  }
}

// mixins
@mixin colors-bg($default, $hover, $disabled) {
  background: $default;

  &:not(:disabled) {
    &:hover {
      background: $hover;
    }
  }

  &:disabled {
    background: $disabled;
  }
}

@mixin colors-text($default, $hover, $disabled) {
  color: $default;

  > svg {
    fill: $default;
  }

  &:not(:disabled) {
    &:hover {
      color: $hover;

      > svg {
        fill: $hover;
      }
    }
  }

  &:disabled {
    color: $disabled;

    > svg {
      fill: $disabled;
    }
  }
}

@mixin colors-border($default, $hover, $disabled, $focus) {
  border-color: $default;

  &:not(:disabled) {
    &:hover {
      border-color: $hover;
    }
  }

  &:disabled {
    border-color: $disabled;
  }

  &:focus {
    border-color: transparent;
    outline: 3px solid $focus;
  }
}
