// 현재 결과표 컬러 시스템을 따르고 있음
// 2023년 상반기 ATS 브랜딩 때에 컬러 팔레트 바뀔 여지 있음

// basic
$color-white: #fff;
$color-black: #000;
$color-guide: #00c8ff;
$color-error: #f0464f;
$color-warning: #ffc82a;

// gray
$color-gray-50: #f8f9fa;
$color-gray-80: #f5f6f7;
$color-gray-100: #f1f3f5; // variables - color-lightgray
$color-gray-200: #e9ecef; // variables - color-gray
$color-gray-300: #dee2e6;
$color-gray-400: #ced4da;
$color-gray-500: #adb5bd;
$color-gray-600: #868e96;
$color-gray-700: #495057; // variables - color-darkgray
$color-gray-800: #343a40;
$color-gray-900: #212529; // variables - color-black

// red
$color-red-50: #fff5f5;
$color-red-100: #ffe3e3;
$color-red-200: #ffc9c9;
$color-red-500: #ff6b6b;
$color-red-600: #fa5252;
$color-red-900: #c92a2a;

// pink
$color-pink-600: #e64980;

// violet
$color-violet-50: #f3f0ff;
$color-violet-100: #e5dbff;
$color-violet-200: #d0bfff;
$color-violet-300: #b197fc;
$color-violet-400: #9775fa;
$color-violet-500: #845ef7;
$color-violet-600: #7950f2;
$color-violet-700: #7048e8;
$color-violet-800: #6741d9;
$color-violet-900: #5f3dc4;

// blue
$color-blue-50: #e7f5ff;
$color-blue-100: #d0ebff;
$color-blue-200: #a5d8ff;
$color-blue-300: #74c0fc;
$color-blue-400: #4dabf7;
$color-blue-500: #339af0;
$color-blue-600: #228be6;
$color-blue-700: #1c7ed6;
$color-blue-800: #1971c2;
$color-blue-900: #1864ab;

// cyan
$color-cyan-50: #e3fafc;
$color-cyan-100: #c5f6fa;
$color-cyan-200: #99e9f2;
$color-cyan-300: #66d9e8;
$color-cyan-400: #3bc9db;
$color-cyan-500: #22b8cf;
$color-cyan-600: #15aabf;
$color-cyan-700: #1098ad;
$color-cyan-800: #0c8599;
$color-cyan-900: #0b7285;

// green
$color-green-50: #ebfbee;
$color-green-100: #d3f9d8;
$color-green-200: #b2f2bb;
$color-green-300: #8ce99a;
$color-green-400: #69db7c;
$color-green-500: #51cf66;
$color-green-600: #40c057;
$color-green-700: #37b24d;
$color-green-800: #2f9e44;
$color-green-900: #2b8a3e;

// lime
$color-lime-50: #f4fce3;
$color-lime-100: #e9fac8;
$color-lime-200: #d8f5a2;
$color-lime-300: #c0eb75;
$color-lime-400: #a9e34b;
$color-lime-500: #94d82d;
$color-lime-600: #82c91e;
$color-lime-700: #74b816;
$color-lime-800: #66a80f;
$color-lime-900: #5c940d;

// yellow
$color-yellow-50: #fff9db;
$color-yellow-100: #fff3bf;
$color-yellow-200: #ffec99;
$color-yellow-300: #ffe066;
$color-yellow-500: #fcc419;
$color-yellow-600: #fab005;
$color-yellow-700: #f59f00;
$color-yellow-800: #f08c00;
$color-yellow-900: #e67700;

// teal
$color-teal-50: #e6fcf5;
$color-teal-100: #c3fae8;
$color-teal-200: #96f2d7;
$color-teal-300: #63e6be;
$color-teal-600: #12b886;
$color-teal-700: #0ca678;

// etc
$color-green: #12b886; // 출처 불명

// 상품 색상
$color-product-trial: $color-cyan-600;
$color-product-select: $color-teal-600;
$color-product-unlimited: $color-pink-600;
$color-product-suite: $color-blue-600;
